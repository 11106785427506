import {useQuery} from "@tanstack/react-query";
import {QueryCacheKeys} from "@hook/query/queryKeys";
import {apiGetMemeLeaderboard} from "@api/meme/apiGetMemeLeaderboard";
import {apiGetFloatingMessage} from "@api/event/getApiFloating";

const useQueryGetFloatingMessage = (id?: string) => {
    const {data, refetch, isLoading} = useQuery({
        queryKey: QueryCacheKeys.event.getFloatingMessage(id),
        staleTime: 60 * 1000 * 5,
        cacheTime: 60 * 1000 * 5,
        refetchOnWindowFocus: false,
        enabled: !!id,
        queryFn: async () => {
            const {data, error} = await apiGetFloatingMessage()
            if (data) return data
            return []
        },
    })
    return {data, refetch, isLoading}
}

export default useQueryGetFloatingMessage
