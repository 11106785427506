import {useQuery} from '@tanstack/react-query'
import {apiGetSidebarUserID, ISidebar} from '@api/user/sidebar/getSidebar'
import {PORTFOLIO_REFRESH_INTERVAL} from '@constant/refreshInterval'
import {useRouter} from 'next/router'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {isNotEmpty} from '@util/strings'

const useQueryGetUserSidebar = (id: string, enabled: boolean = true, refetchEnabled: boolean = true) => {
    const router = useRouter()

    return useQuery({
        queryKey: QueryCacheKeys.portfolio.getUserSidebar(id, router?.locale),
        enabled: enabled && isNotEmpty(id),
        refetchInterval: refetchEnabled && enabled ? PORTFOLIO_REFRESH_INTERVAL : false,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const {data, error} = await apiGetSidebarUserID(id, {cookie: {language: router?.locale}})
            if (data) return data
            return {} as ISidebar
        },
    })
}

export default useQueryGetUserSidebar
